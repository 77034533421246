body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.navbar {
  height: 100%;
  width: 200px;
  position: fixed;
  overflow-x: hidden;
  padding-top: 20px;
  background-color: #575757;
}
.navbara{
  
  width: 250px;
  position: fixed;
  overflow-x: hidden;
  padding-top: 20px;
  bottom: 0;
  margin-left: 15px;
}
.navbara a{
  padding: 10px 15px;
  text-decoration: none;
  font-size: 15px;
  color: rgb(175, 169, 169) ;
  display: block;
  
}

.navbar a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 15px;
  color: rgb(175, 169, 169) ;
  display: block;
  
}
.navbar :hover{
  color: red;

}

.navbar a:hover {
  background-color: #575757;
}

.active {
  background-color: #4CAF50;
}

.logo {
  color: white;
  padding: 10px 15px;
  text-align: center;
}

.logout {
  position: absolute;
  bottom: 0;
  left: 0;
  /* padding: 10px 15px; */
  /* margin-left: 20px; */

}

.logout a {
  text-decoration: none;
  color: white;

  padding: 5px 15px;
  border-radius: 5px;
}

.logout a:hover {
  background-color: darkred;
}
font{
  margin-left: 13px;
  color: white;
}
.logo_image{
 margin-top: 0px;
  width: 66%;
  
  height: 20% !important;
}

.logou{
  margin-bottom: 10px;
}
.text{
  color: black;
}
.flex_container{
  display: flex;
  /* border: 10px solid black; */
  justify-content: space-between;
}
.sideb{
  width: 20%;
}
.center_div{
  width: 90%;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projects{
  width: 30% !important;
  
}
.project_recommendation{
  height: 30vh;
  background-color: #d9d9d9;
  margin: 1%;
  width: 96%;
  

}
.projanaly{
  display: flex;
  flex-direction: column;
  width: 80%;
}
.projects_analytics
{
  display: flex;
}
.idnames{
  width: 20%;
  padding: 2%;
}
.projec{
  background-color: #d9d9d9;
  height: 25.3vh;
  margin: 2%;
  width: 60vw;
}
.analytics{
  background-color: #d9d9d9;
  height: 38vh;
  margin: 2%;
  width: 60vw;
}
.project_text{
  color: black;
  font-size: smaller;
  margin: 40px !important;
  padding: 0 !important;
}           
.john_text{
  color: black;
font-weight: bold;
}
.to_do{
  background-color: #d9d9d9;
  height: 58vh;
  margin: 2%;

}
.person_image{
  width: 14%;
  border-radius: 50%;
  border: 1px solid black;
}
.client_name{
  display: flex;
}
.projects_text{
  background-color: black;
  padding: 0.5% !important;
  margin: 0 !important;
  font-size: small;
  
}