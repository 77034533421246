@import "../../../styles/responsive.scss";

.bigChartBox{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color:white;

  h1{
    @include xxl{
      font-size: 24px;
    }
  }
  .chart{
    width: 100%;
    height: 300px;
  }
}