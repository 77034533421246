#descriptionContact{
    font-size: 20px;
    margin-left: 200px;
    margin-right: 250px;
    margin-top: 50px;
    max-width: 600px; /* Adjust max-width as needed */

  }
  
  #sendContact{
    display: block;
    float: right;
  }
  
  .headerTextContact{
    position: relative;
  }
  
  .textOnImageContact{
    position: absolute;
    color: white;
    font-family: 'myanmar-khyay-regular', sans-serif;
    font-size: 5rem;
    margin-top: 190px;
    margin-left: 160px;
    text-underline-offset: 20px;
  }
  .bgImg{
    max-width: 200%;
    height: auto;
    display: block;
    margin: 0 auto;

  }

  .fullName, .email {
    width: 100%; 
    max-width: 600px; 
    padding: 0.4rem; 
    border: 1px solid #ccc; 
    box-sizing: border-box; 
    color: black;
  }
  .message {
    width: 100%; 
    height: 150px;
    margin-bottom: 1rem; 
    padding: 1rem; 
    font-size: 1rem; 
    border: 1px solid #ccc;
    box-sizing: border-box; 
  }

  .messageHeader{
    color: white;
  }